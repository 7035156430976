var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col items-center p-1 duration-75 mobile:w-full",class:_vm.isWide ? 'w-1/3' : 'w-1/4',on:{"click":_vm.toggleExpand}},[_c('div',{staticClass:"file-container w-full flex flex-row flex-wrap rounded-xl items-center mobile:py-2 mobile:px-2 duration-100 shadow cursor-pointer",class:[
      !_vm.isWhite && !_vm.currentExpanded ? 'bg-offwhite' : '',
      _vm.isWhite && !_vm.currentExpanded ? 'bg-white' : '',
      _vm.currentExpanded && _vm.currentExpanded === _vm.file.id
        ? 'text-white bg-green shadow-outline'
        : 'text-darkgrey bg-offwhite hover:bg-darkgreen hover:text-white hover:shadow-outline',
      _vm.currentExpanded ? '' : 'h-full'
    ]},[_c('div',{staticClass:"flex p-2 justify-center items-center w-full h-full"},[_c('div',{staticClass:"flex"},[_c('img',{staticClass:"icon",attrs:{"src":_vm.parseFileTypeIcon()}})]),_c('div',{staticClass:"flex flex-col justify-center w-full h-full"},[_c('div',{staticClass:"flex flex-row items-center w-full font-semibold"},[_c('span',{staticClass:"w-full pl-2 break-words"},[_vm._v(" "+_vm._s(_vm.file.description !== "" ? _vm.file.description : _vm.file.file_name))])]),_c('div',{staticClass:"flex items-end justify-center"},[_c('span',{staticClass:"w-full pl-2"},[_vm._v(" "+_vm._s(_vm.file.uploaded_by))])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }