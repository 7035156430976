export default {
  methods: {
    humanizeSize(input) {
      let exponent;
      let num = input;
      const neg = num < 0;
      const units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      if (num) {
        if (neg) num = -num;
        if (num < 1) return `${num} B`;

        exponent = Math.min(
          Math.floor(Math.log(num) / Math.log(1024)),
          units.length - 1
        );
        num = (num / Math.pow(1024, exponent)).toFixed(2);

        return `${neg ? "-" : ""}${num} ${units[exponent]}`;
      }
      return "-";
    }
  }
};
