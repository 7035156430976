<template>
  <div class="modal">
    <div class="modal-body modal-medium" v-on-clickaway:mousedown="closeModal">
      <div
        class="w-full bg-white rounded-gwdhalf mobile:flex-col h-full modal-content"
      >
        <div class="flex flex-col w-9/12 mobile:w-full h-full">
          <div class="flex w-full">
            <h3>Lisa dokumente</h3>
          </div>
          <div class="flex w-full pr-4 mobile:pr-0 h-full flex-grow">
            <file-widget @filesUpdated="handleFileUpdate"></file-widget>
          </div>
        </div>

        <div class="flex flex-col pl-2 justify-center" v-if="sizesInited">
          <h4>Kasutamata andmemaht</h4>
          <span>{{ humanizeSize(remainingSize) }}</span>
          <h4>Kogu andmemaht</h4>
          <span>{{ humanizeSize(totalSize) }}</span>
          <h4>Üleslaetavate failide maht</h4>
          <span class="mb-4">{{ humanizeSize(uploadedFileSize) }}</span>
          <div class="flex w-full">
            <div class="bg-white w-full h-4 shadow rounded-md flex">
              <div class="bg-green h-full rounded-md" id="usedSpace"></div>
              <div class="bg-pending h-full" id="incomingSpace"></div>
            </div>
          </div>
          <div v-if="fileSizeWarning" class="bg-danger p-3 rounded">
            <span class="text-white font-semibold">
              Üleslaetavate failide maht ületab lubatud suuruse!
            </span>
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar">
        <div class="flex">
          <button
            @click="uploadFiles"
            class="new-button-green mr-3"
            :class="
              fileSizeWarning || filesToSave.length === 0
                ? 'disabled hover:shadow hover:bg-offwhite -disabled cursor-default'
                : ''
            "
            :disabled="fileSizeWarning || filesToSave.length === 0"
          >
            <span class="typcn typcn-tick icon"></span>
            <span class="label">Lae üles</span>
          </button>
          <button class="new-button-danger" @click="closeModal()">
            <span class="typcn typcn-times icon"></span>
            <span class="label">Sulge</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileWidget from "@/components/reusable/FileWidget";
import RequestHandler from "@/assets/mixins/RequestHandler";
import FileSizeHumanizer from "@/assets/mixins/FileSizeHumanizer";
import { directive as onClickaway } from "vue-clickaway2";
export default {
  props: {
    draft: {
      type: Object,
      default: null
    },
    worker: {
      type: Object,
      default: null
    },
    activeFileGroup: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      filesToSave: [],
      fileSizeWarning: false,
      remainingSizeNormalized: "",
      remainingSize: 0,
      uploadedFileSize: 0,
      usedSize: 0,
      sizesInited: false
    };
  },
  components: {
    FileWidget
  },
  directives: {
    onClickaway: onClickaway
  },
  mixins: [RequestHandler, FileSizeHumanizer],
  mounted() {
    this.getUsedSpace();
  },
  methods: {
    getUsedSpace() {
      this.apiRequest(
        `company/${this.$store.state.companyData.activeCompany.uuid}/util/`,
        "get",
        true
      ).then(res => {
        this.remainingSize = res.data.remaining_storage_size;
        this.usedSize = res.data.used_storage_size;
        this.sizesInited = true;
        this.$nextTick(() => {
          this.initSpaceChart();
        });
      });
    },
    closeModal() {
      this.$emit("closeModal");
    },
    handleFileUpdate(e) {
      this.filesToSave = e;
    },
    calculateUploadSize() {
      if (this.filesToSave.length > 0) {
        let num = 0;
        for (let i = 0; i < this.filesToSave.length; i++) {
          num += this.filesToSave[i].size;
        }
        this.fileSizeWarning = num > this.remainingSize ? true : false;
        this.uploadedFileSize = num;
        this.initSpaceChart();
      }
    },
    uploadFiles() {
      if (this.worker) this.addWorkerSpecificFiles();
      else if (this.draft) this.addFilesToDraft();
      else this.addFilesToCompany();
    },
    addFilesToCompany() {
      this.$store.state.loading = true;
      if (this.filesToSave.length > 0) {
        const filesToUpload = this.generateFilesToSave();
        this.apiRequest(
          "company/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/files/add/",
          "post",
          true,
          filesToUpload
        )
          .then(res => {
            if (res.status === 201) {
              if (this.activeFileGroup) {
                let files = res.data;
                let data = [];
                for (let i = 0; i < files.length; i++) {
                  data.push({
                    id: files[i],
                    category: this.activeFileGroup.id
                  });
                }
                this.apiRequest(
                  "company/" +
                    this.$store.state.companyData.activeCompany.uuid +
                    "/files/edit/multiple/",
                  "patch",
                  true,
                  data
                ).then(res2 => {
                  if (res2) {
                    this.$emit("filesUpdated");

                    this.filesToSave = [];
                    this.$store.state.loading = false;
                    this.closeModal();
                  }
                });
              } else {
                this.$emit("filesUpdated");
                this.filesToSave = [];
                this.$store.state.loading = false;
                this.closeModal();
              }
            }
          })
          .catch(error => {
            this.$store.state.loading = false;
            const errObj = { error };
            if (errObj.error.response.status === 413) {
              window.scrollTo(0, 0);
            }
          });
      } else {
        this.$store.state.loading = false;
      }
    },
    addFilesToDraft() {
      this.$store.state.loading = true;
      if (this.filesToSave.length > 0) {
        const filesToUpload = this.generateFilesToSave();
        this.apiRequest(
          "notes/draft/" + this.draft.uuid + "/files/add/",
          "post",
          true,
          filesToUpload
        )
          .then(res => {
            if (res.status === 201) {
              this.$store.state.loading = false;
              this.filesToSave = null;
              this.$emit("filesUpdated");
              this.$emit("closeModal");
            }
          })
          .catch(error => {
            this.$store.state.loading = false;
            const errObj = { error };
            if (errObj.error.response.status === 413) {
              window.scrollTo(0, 0);
            }
          });
      }
    },
    addWorkerSpecificFiles() {
      this.$store.state.loading = true;
      if (this.filesToSave.length > 0) {
        const filesToUpload = this.generateFilesToSave();
        this.apiRequest(
          "company/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/worker/" +
            this.$route.params.id +
            "/files/add/",
          "post",
          true,
          filesToUpload
        )
          .then(res => {
            if (res.status === 201) {
              this.$store.state.loading = false;
              this.filesToSave = null;
              this.$emit("filesUpdated");
              this.$emit("closeModal");
            }
            this.$store.state.loading = false;
          })
          .catch(error => {
            this.$store.state.loading = false;
            const errObj = { error };
            if (errObj.error.response.status === 413) {
              window.scrollTo(0, 0);
            }
          });
      } else {
        this.$store.state.loading = false;
      }
    },
    generateFilesToSave() {
      const filesToUpload = new FormData();
      for (let i = 0; i < this.filesToSave.length; i++) {
        const file = this.filesToSave[i];
        const fileToAppend = new File([file], file.name, { type: file.type });
        filesToUpload.append("files", fileToAppend, file.name);
      }
      return filesToUpload;
    },
    initSpaceChart() {
      let remainingSizeBar = document.getElementById("usedSpace");
      let newSizeBar = document.getElementById("incomingSpace");
      remainingSizeBar.style.width =
        (this.usedSize / this.totalSize) * 100 + "%";
      if (
        (this.remainingSize / this.totalSize) * 100 +
          (this.uploadedFileSize / this.totalSize) * 100 >
        100
      ) {
        newSizeBar.style.width ===
          100 - (this.remainingSize / this.totalSize) * 100 + "%";
        newSizeBar.classList.add("overbudget");
      } else {
        newSizeBar.style.width =
          (this.uploadedFileSize / this.totalSize) * 100 + "%";
        newSizeBar.classList.remove("overbudget");
      }
    }
  },
  computed: {
    totalSize() {
      return this.remainingSize + this.usedSize;
    }
  },
  watch: {
    filesToSave: {
      deep: true,
      handler(val) {
        let runningTotal = 0;
        val.forEach(file => {
          runningTotal += file.size;
        });

        this.uploadedFileSize = runningTotal;
        this.calculateUploadSize();
      }
    }
  }
};
</script>
