<template>
  <div class="flex flex-row mb-2 w-full">
    <div class="file-input duration-75 w-full">
      <input
        type="file"
        multiple
        class="bg-white"
        ref="files"
        name="fields[assetsFieldHandle][]"
        id="assetsFieldHandle"
        @input="updateFiles"
      />
      <div
        v-for="(item, index) in fileList"
        :key="item.name"
        class="flex mobile:flex-col font-semibold py-1 rounded-full mb-1 w-full text-darkgrey items-center"
      >
        <span class="truncate w-full flex-grow flex pr-2 mobile:pr-0">{{
          item.name
        }}</span>
        <span class="w-auto flex mr-2">{{ humanizeSize(item.size) }}</span>
        <button class="w-auto new-button-danger" @click="removeItem(index)">
          <span class="icon">
            <img src="/bc21/trash.svg" class="filter-to-white"
          /></span>
          <span class="label">Eemalda</span>
        </button>
      </div>
      <label
        class="hover:bg-green hover:text-white rounded-gwdhalf p-2 font-semibold text-sm cursor-pointer mb-2 flex flex-col items-center justify-center w-full h-full border-2 border-green border-dotted"
        @drop="handleDrop"
        @dragover="dragover"
        @dragleave="dragleave"
        for="assetsFieldHandle"
        ><span class="typcn typcn-upload text-4xl leading-none mb-2"></span> Vea
        failid siia, või vajuta peale, et üles laadida</label
      >
      <button
        class="new-button-danger mt-auto"
        v-if="fileList.length > 0"
        @click="reset"
      >
        <span class="icon typcn typcn-times"></span>
        <span class="label">Tühjenda</span>
      </button>
    </div>
  </div>
</template>

<script>
import FileSizeHumanizer from "@/assets/mixins/FileSizeHumanizer";
export default {
  data() {
    return {
      fileList: []
    };
  },
  mixins: [FileSizeHumanizer],
  methods: {
    updateFiles() {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.fileList.push(this.$refs.files.files[i]);
      }
      this.$emit("filesUpdated", this.fileList);
    },
    removeItem(item) {
      this.fileList.splice(item, 1);
      this.$refs.files.value = null;
      this.$emit("filesUpdated", this.fileList);
    },
    handleDrop(e) {
      e.preventDefault();
      this.$refs.files.files = e.dataTransfer.files;
      this.updateFiles();
      e.currentTarget.classList.remove("bg-darkgreen");
      e.currentTarget.classList.remove("text-white");
    },
    dragover(e) {
      e.preventDefault();
      e.currentTarget.classList.add("bg-darkgreen");
      e.currentTarget.classList.add("text-white");
    },
    dragleave(e) {
      if (e.currentTarget.classList.contains("bg-darkgreen")) {
        e.currentTarget.classList.remove("bg-darkgreen");
        e.currentTarget.classList.remove("text-white");
      }
    },
    reset() {
      this.fileList = [];
      this.$refs.files.value = "";
      this.$emit("filesUpdated", this.fileList);
    }
  }
};
</script>

<style lang="scss">
.file-input {
  @apply rounded-xl flex flex-col items-center h-auto relative;
  min-height: 200px;
  input {
    @apply p-0 absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 0.1px;
    width: 0.1px;
  }
}
</style>
